import { IPostExportCourseProfessionalCertificateThunkPayload, IPostExportProfessionalCvThunkPayload } from '../redux/slices/documents.slice';
import AxiosService from '../services/axios.service';
import { ApiEndpoints } from './api.config';

export const postExportCourseProfessionalCertificate = (payload: IPostExportCourseProfessionalCertificateThunkPayload) => {
    const endpoint = ApiEndpoints.DocumentCourseProfessionalCertificate.toString();
    let querystring = Array<string>();
    if (payload.codiceFiscale && payload.codiceFiscale.length > 0) {
      querystring.push('codiceFiscale=' + payload.codiceFiscale.toString());
    }
    if (payload.idCorso && payload.idCorso > 0) {
      querystring.push('idCorso=' + payload.idCorso.toString());
    }
    const url = endpoint + (querystring.length ? '?' + querystring.join('&') : '');
    return AxiosService.instance.axios.post(url);
};

export const postExportProfessionalCv = (payload: IPostExportProfessionalCvThunkPayload) => {
  const endpoint = ApiEndpoints.DocumentProfessionalCv.toString();
  let querystring = Array<string>();
  if (payload.codiceFiscale && payload.codiceFiscale.length > 0) {
    querystring.push('codiceFiscale=' + payload.codiceFiscale.toString());
  }
  if (payload.idTriennio && payload.idTriennio > 0) {
    querystring.push('idTriennio=' + payload.idTriennio.toString());
  }
  if (payload.tipoAttestato && (payload.tipoAttestato == 1 || payload.tipoAttestato == 2)) {
    querystring.push('tipoAttestato=' + payload.tipoAttestato.toString());
  }
  const url = endpoint + (querystring.length ? '?' + querystring.join('&') : '');
  return AxiosService.instance.axios.post(url);
};

const DocumentAPI = {
    postExportCourseProfessionalCertificate,
    postExportProfessionalCv,
};

export default DocumentAPI;